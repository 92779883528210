import { supabase } from "./supabase";
import { Elm, User } from "../elm/Main.elm";

// MAIN

(async () => {
  try {
    const currentUser = await getCurrentUser();
    const accessToken = await getAccessToken();
    initApp({
      user: currentUser,
      accessToken,
      onAccessTokenRefresh,
      onPreferencesConsentGiven,
    });
    onSignOut(() => {
      window.location.href = "/login/";
    });
    window["__supabase"] = supabase;
  } catch (error) {
    window.location.href = "/login/";
  }
})();

// INIT APP

function initApp({
  user,
  accessToken,
  onAccessTokenRefresh,
  onPreferencesConsentGiven,
}: {
  user: User;
  accessToken: string;
  onAccessTokenRefresh: (handler: (newToken: string) => void) => void;
  onPreferencesConsentGiven: (handler: () => void) => void;
}): void {
  const app = Elm.Main.init({
    flags: {
      user,
      accessToken,
      supabaseApiUrl: process.env.SUPABASE_API_URL!,
      apiKey: process.env.SUPABASE_ANON_KEY!,
      statsVisibility: getFromLocalStorage<boolean>("statsVisibility", false),
      nakr: getFromLocalStorage<string>("nakr", ""), // Numista API key (reversed)
      copyrightYear: new Date().getFullYear(),
      version: getAppVersion() ?? "?",
    },
  });

  onAccessTokenRefresh((newToken) => {
    app.ports.onAccessTokenRefresh.send(newToken);
  });

  onPreferencesConsentGiven(() => {
    console.log("[cookiebot] preferences consent given");
    app.ports.onPreferencesConsentGiven.send(null);
  });

  app.ports.elmToJs.subscribe((portData) => {
    switch (portData.action) {
      case "log": {
        const { name, value } = portData.payload;
        console.log("[Ports.log]", name, value);
        break;
      }

      case "alert": {
        alert(portData.payload);
        break;
      }

      case "scrollLock": {
        document.body.classList.toggle("scroll-lock", portData.payload);
        break;
      }

      case "storeInLocalStorage": {
        const { key, value } = portData.payload;
        storeInLocalStorage(key, value);
        break;
      }

      case "logout": {
        supabase.auth.signOut();
        break;
      }

      default: {
        throw new UnsupportedValueError(portData);
      }
    }
  });
}

// HELPERS

/**
 * Custom error used for exhaustiveness checking:
 * https://2ality.com/2020/02/typescript-exhaustiveness-checks-via-exceptions.html
 */
class UnsupportedValueError extends Error {
  constructor(value: never) {
    super("Unsupported value: " + value);
  }
}

function getAppVersion(): string | undefined {
  const sel = "meta[name='app-version']";
  const $el = document.head.querySelector(sel) as HTMLMetaElement | undefined;
  return $el?.content;
}

function getFromLocalStorage<A>(key: string, defaultValue: A): A {
  try {
    const value = localStorage.getItem(key);
    return value ? (JSON.parse(value) as A) : defaultValue;
  } catch (error) {
    console.error("getFromLocalStorage", error);
    return defaultValue;
  }
}

function storeInLocalStorage(key: string, value: unknown): void {
  try {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  } catch (error) {
    console.error("storeInLocalStorage", error);
  }
}

async function getCurrentUser(): Promise<User> {
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser();
  if (error || !user) throw error;
  return { id: user.id, email: user.email as string };
}

async function getAccessToken(): Promise<string> {
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession();
  if (error || !session) throw error;
  return session.access_token;
}

function onAccessTokenRefresh(handler: (newToken: string) => void): void {
  supabase.auth.onAuthStateChange((event, session) => {
    if (event == "TOKEN_REFRESHED" && session) {
      handler(session.access_token);
    }
  });
}

function onPreferencesConsentGiven(handler: () => void): void {
  // Always give preferences consent in development (Cookiebot n/a anyway)
  if (process.env.NODE_ENV === "development") {
    handler();
    return;
  }

  // Check preferences consent for production
  if (Cookiebot.consent.preferences) {
    handler();
  } else {
    window.addEventListener(
      "CookiebotOnConsentReady",
      () => {
        if (Cookiebot.consent.preferences) {
          handler();
        }
      },
      false
    );
  }
}

function onSignOut(handler: () => void): void {
  supabase.auth.onAuthStateChange((event) => {
    if (event == "SIGNED_OUT") {
      handler();
    }
  });
}
