module Main exposing (main)

import Browser
import Browser.Events
import Browser.Navigation
import Cmd.Extra
import Data.ApiAccess
import Data.Coin exposing (Coin)
import Data.Flags exposing (Flags)
import Data.GridSize exposing (GridSize)
import Data.Sorting
import Data.User
import Debouncer exposing (Debouncer)
import Dialog
import Dialog.View
import Effect exposing (Effect)
import GlobalMsg exposing (GlobalMsg)
import Html exposing (Html)
import Html.Attributes
import Html.Attributes.Extra
import Html.Events
import Html.Extra
import Icons
import Json.Decode
import Json.Encode
import Maybe.Extra
import Money exposing (Currency)
import Ports
import Route exposing (Route)
import UI
import Url exposing (Url)
import Util


type Msg
    = NoOp
    | AddCoinButtonClicked
    | BurgerButtonClicked
    | CoinObverseButtonClicked
    | CoinSelected Data.Coin.Id
    | DialogMsg Dialog.Msg
    | DiameterRatioButtonClicked
    | EscPressed
    | FilterButtonClicked
    | GotAccessToken String
    | GotPreferencesConsent
    | GridSizeSelected GridSize
    | ModalClosed Dialog.Model
    | NavigationClosed
    | ProfileButtonClicked
    | SearchDebouncerChanged Debouncer.Msg
    | SearchDebouncerReady String
    | SearchInputChanged String
    | SortButtonClicked
    | UrlChanged Url
    | UrlRequested Browser.UrlRequest
    | WrappedGlobalMsg GlobalMsg


type RootModel
    = FailedToInitialize Json.Decode.Error
    | Initialized Model


type alias Model =
    { coins : List Coin
    , isPending : Bool
    , navKey : Browser.Navigation.Key
    , route : Route
    , searchInput : String
    , searchDebouncer : Debouncer String
    , currency : Currency
    , flags : Flags
    , numistaApiKey : String
    , showStats : Bool
    , isNavigationVisible : Bool
    , modalDialog : Maybe Dialog.Model
    , hasPreferencesConsent : Bool
    }


main : Program Json.Decode.Value RootModel Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = UrlRequested
        , onUrlChange = UrlChanged
        }


init :
    Json.Decode.Value
    -> Url
    -> Browser.Navigation.Key
    -> ( RootModel, Cmd Msg )
init rawFlags url navKey =
    let
        route =
            Route.fromUrl url
    in
    case decodeFlags rawFlags of
        Ok flags ->
            let
                model =
                    { coins = []
                    , isPending = True
                    , navKey = navKey
                    , route = route
                    , searchInput = toInitialSearchInput route
                    , searchDebouncer = Debouncer.init
                    , currency = Money.EUR
                    , flags = flags
                    , numistaApiKey = String.reverse flags.nakr
                    , showStats = flags.statsVisibility
                    , isNavigationVisible = False
                    , modalDialog = Nothing
                    , hasPreferencesConsent = False
                    }

                effect =
                    Effect.Batch
                        [ Effect.FetchAllCoins
                        , Effect.Log "user" <| Data.User.encode flags.user
                        ]
            in
            Initialized model
                |> Cmd.Extra.withCmd (toCmd model effect)

        Err error ->
            FailedToInitialize error
                |> Cmd.Extra.withNoCmd


update : Msg -> RootModel -> ( RootModel, Cmd Msg )
update msg rootModel =
    case rootModel of
        FailedToInitialize _ ->
            rootModel |> Cmd.Extra.withNoCmd

        Initialized model ->
            updateInitialized msg model
                |> Tuple.mapBoth Initialized (toCmd model)


toCmd : Model -> Effect Msg -> Cmd Msg
toCmd model =
    Effect.toCmd (toEffectConfig model) WrappedGlobalMsg


view : RootModel -> Browser.Document Msg
view rootModel =
    case rootModel of
        FailedToInitialize _ ->
            viewInitializationError ()

        Initialized model ->
            viewInitialized model


subscriptions : RootModel -> Sub Msg
subscriptions rootModel =
    case rootModel of
        FailedToInitialize _ ->
            Sub.none

        Initialized _ ->
            Sub.batch
                [ Ports.onAccessTokenRefresh GotAccessToken
                , Ports.onPreferencesConsentGiven
                    (always GotPreferencesConsent)
                , Browser.Events.onKeyUp (singleKeyDecoder "Escape" EscPressed)
                ]



-- HELPERS


searchDebouncerConfig : Debouncer.Config String Msg
searchDebouncerConfig =
    Debouncer.trailing
        { wait = 250
        , onReady = SearchDebouncerReady
        , onChange = SearchDebouncerChanged
        }


singleKeyDecoder : String -> Msg -> Json.Decode.Decoder Msg
singleKeyDecoder matchKey msg =
    Json.Decode.field "key" Json.Decode.string
        |> Json.Decode.andThen
            (\key ->
                if key == matchKey then
                    Json.Decode.succeed msg

                else
                    Json.Decode.fail "key doesn't match"
            )


updateInitialized : Msg -> Model -> ( Model, Effect Msg )
updateInitialized msg model =
    let
        skip =
            model
                |> Effect.withNoEffect
    in
    case msg of
        UrlChanged url ->
            let
                nextRoute =
                    Route.fromUrl url
            in
            case nextRoute of
                Route.Gallery _ ->
                    model
                        |> withRoute nextRoute
                        |> Effect.withNoEffect

                _ ->
                    model
                        |> withRoute nextRoute
                        |> Effect.withEffect Effect.ResetViewport

        UrlRequested request ->
            case request of
                Browser.External urlString ->
                    model
                        |> Effect.withEffect (Effect.LoadUrl urlString)

                Browser.Internal url ->
                    let
                        urlString =
                            Url.toString url

                        isAppPath =
                            String.startsWith "/app/" url.path
                                || (url.path == "/app")
                    in
                    if isAppPath then
                        model
                            |> Effect.withEffect (Effect.Navigate urlString)

                    else
                        model
                            |> Effect.withEffect (Effect.LoadUrl urlString)

        GridSizeSelected gridSize ->
            model
                |> withGalleryOptions
                    (\options -> { options | gridSize = gridSize })

        DiameterRatioButtonClicked ->
            model
                |> withGalleryOptions
                    (\options ->
                        { options
                            | displayCoinDiameterRatio =
                                not options.displayCoinDiameterRatio
                        }
                    )

        CoinObverseButtonClicked ->
            model
                |> withGalleryOptions
                    (\options ->
                        { options
                            | showCoinObverse = not options.showCoinObverse
                        }
                    )

        SearchInputChanged value ->
            model
                |> withSearchInput value
                |> callSearchDebouncer value

        SearchDebouncerChanged debouncerMsg ->
            model
                |> updateSearchDebouncer debouncerMsg

        SearchDebouncerReady value ->
            model
                |> withGalleryOptions
                    (\options ->
                        { options | searchTerms = Route.toSearchTerms value }
                    )
                |> Effect.addEffect Effect.ResetViewport

        SortButtonClicked ->
            case model.route of
                Route.Gallery options ->
                    model
                        |> withModalDialogEffect
                            (Dialog.initSortingOverlay options.sorting)

                _ ->
                    skip

        FilterButtonClicked ->
            case model.route of
                Route.Gallery options ->
                    model
                        |> withModalDialogEffect
                            (Dialog.initFilterOverlay
                                { filters = options.filters
                                , coins = model.coins
                                , currency = model.currency
                                }
                            )

                _ ->
                    skip

        GotAccessToken token ->
            model
                |> withAccessToken token
                |> Effect.withNoEffect

        GotPreferencesConsent ->
            model
                |> withPreferencesConsent
                |> Effect.withNoEffect

        ProfileButtonClicked ->
            model
                |> withModalDialogEffect
                    (Dialog.initProfileOverlay
                        { flags = model.flags
                        , showStats = model.showStats
                        , hasCoins = hasCoins model
                        }
                    )

        AddCoinButtonClicked ->
            model
                |> Effect.withEffect
                    (Effect.Broadcast GlobalMsg.OpenCoinImportDialog)

        ModalClosed modalDialog ->
            case modalDialog of
                Dialog.CoinDetailsModel _ ->
                    model
                        |> withoutModalDialog
                        |> Effect.withEffect (Effect.ScrollLock False)

                _ ->
                    model
                        |> withoutModalDialog
                        |> Effect.withNoEffect

        CoinSelected id ->
            case getCoin model id of
                Just coin ->
                    model
                        |> withModalDialogEffect
                            (Dialog.initCoinDetails
                                { coin = coin
                                , currency = model.currency
                                }
                            )
                        |> Effect.addEffect (Effect.ScrollLock True)

                _ ->
                    skip

        NavigationClosed ->
            model
                |> withNavigationVisibility False
                |> Effect.withNoEffect

        BurgerButtonClicked ->
            model
                |> withNavigationVisibility True
                |> Effect.withNoEffect

        EscPressed ->
            if model.isNavigationVisible then
                model
                    |> withNavigationVisibility False
                    |> Effect.withNoEffect

            else if hasModalDialog model then
                model
                    |> withoutModalDialog
                    |> Effect.withEffect (Effect.ScrollLock False)

            else
                model
                    |> Effect.withNoEffect

        DialogMsg dialogMsg ->
            case model.modalDialog of
                Just dialogModel ->
                    model
                        |> withModalDialogEffect
                            (Dialog.update dialogMsg dialogModel)

                _ ->
                    skip

        WrappedGlobalMsg globalMsg ->
            let
                ( nextModel, dialogEffect ) =
                    case model.modalDialog of
                        Just dialogModel ->
                            model
                                |> withModalDialogEffect
                                    (Dialog.updateGlobalMsg
                                        globalMsg
                                        dialogModel
                                    )

                        _ ->
                            skip
            in
            nextModel
                |> updateGlobalMsg globalMsg
                |> Effect.addEffect dialogEffect

        NoOp ->
            skip


updateGlobalMsg : GlobalMsg -> Model -> ( Model, Effect Msg )
updateGlobalMsg globalMsg model =
    case globalMsg of
        GlobalMsg.GotCoinDeletion result ->
            case result of
                Ok _ ->
                    model
                        |> withCoins []
                        |> Effect.withNoEffect

                Err _ ->
                    model
                        |> Effect.withEffect
                            (Effect.Alert "Deleting coins failed")

        GlobalMsg.GotNumistaImport result ->
            case result of
                Ok _ ->
                    model
                        |> Effect.withEffect Effect.FetchAllCoins
                        |> Effect.addEffectIf model.hasPreferencesConsent
                            (\_ ->
                                Effect.StoreInLocalStorage
                                    { key = "nakr" -- Numista API key (reversed)
                                    , value =
                                        model.numistaApiKey
                                            |> String.reverse
                                            |> Json.Encode.string
                                    }
                            )

                Err _ ->
                    model
                        |> Effect.withNoEffect

        GlobalMsg.GotCoins result ->
            case result of
                Ok coins ->
                    model
                        |> withCoins coins
                        |> withIsPending False
                        |> Effect.withNoEffect

                Err _ ->
                    model
                        |> withIsPending False
                        |> Effect.withEffect
                            (Effect.Alert "Fetching coins failed")

        GlobalMsg.StatsVisibilityChanged isVisible ->
            model
                |> withStatsVisibility isVisible
                |> Effect.withEffect Effect.ResetViewport
                |> Effect.addEffectIf model.hasPreferencesConsent
                    (\_ ->
                        Effect.StoreInLocalStorage
                            { key = "statsVisibility"
                            , value = Json.Encode.bool isVisible
                            }
                    )

        GlobalMsg.NumistaApiKeyUpdated value ->
            model
                |> withNumistaApiKey value
                |> Effect.withNoEffect

        GlobalMsg.OpenCoinImportDialog ->
            model
                |> withModalDialogEffect
                    (Dialog.initCoinImportOverlay
                        { numistaApiKey = model.numistaApiKey }
                    )

        GlobalMsg.NoOp ->
            model
                |> Effect.withNoEffect


decodeFlags : Json.Decode.Value -> Result Json.Decode.Error Flags
decodeFlags value =
    Json.Decode.decodeValue Data.Flags.decoder value


toInitialSearchInput : Route -> String
toInitialSearchInput route =
    case route of
        Route.Gallery { searchTerms } ->
            Route.toSearchInput searchTerms

        _ ->
            ""



-- MODEL MODIFIERS


withPreferencesConsent : Model -> Model
withPreferencesConsent model =
    { model | hasPreferencesConsent = True }


withStatsVisibility : Bool -> Model -> Model
withStatsVisibility isVisible model =
    { model | showStats = isVisible }


withModalDialogEffect :
    ( Dialog.Model, Effect Dialog.Msg )
    -> Model
    -> ( Model, Effect Msg )
withModalDialogEffect ( nextDialogModel, dialogEffect ) model =
    model
        |> withModalDialog nextDialogModel
        |> Effect.withEffect (Effect.map DialogMsg dialogEffect)


withModalDialog : Dialog.Model -> Model -> Model
withModalDialog dialogModel model =
    { model | modalDialog = Just dialogModel }


withoutModalDialog : Model -> Model
withoutModalDialog model =
    { model | modalDialog = Nothing }


withSearchInput : String -> Model -> Model
withSearchInput value model =
    { model | searchInput = value }


withNumistaApiKey : String -> Model -> Model
withNumistaApiKey value model =
    { model | numistaApiKey = String.trim value }


withCoins : List Coin -> Model -> Model
withCoins coins model =
    { model | coins = coins }


withIsPending : Bool -> Model -> Model
withIsPending value model =
    { model | isPending = value }


withRoute : Route -> Model -> Model
withRoute route model =
    { model | route = route }


withFlags : Flags -> Model -> Model
withFlags flags model =
    { model | flags = flags }


withNavigationVisibility : Bool -> Model -> Model
withNavigationVisibility isVisible model =
    { model | isNavigationVisible = isVisible }


withAccessToken : String -> Model -> Model
withAccessToken accessToken model =
    let
        apiAccess =
            Data.ApiAccess.withAccessToken accessToken model.flags.apiAccess

        flags =
            Data.Flags.withApiAccess apiAccess model.flags
    in
    withFlags flags model


withGalleryOptions :
    (Route.GalleryOptions -> Route.GalleryOptions)
    -> Model
    -> ( Model, Effect Msg )
withGalleryOptions transform model =
    case model.route of
        Route.Gallery options ->
            let
                newUrl =
                    Route.toGalleryUrl (transform options)
            in
            model
                |> Effect.withEffect (Effect.ReplaceUrl newUrl)

        _ ->
            model
                |> Effect.withNoEffect


withSearchDebouncer : Debouncer String -> Model -> Model
withSearchDebouncer debouncer model =
    { model | searchDebouncer = debouncer }


updateSearchDebouncer : Debouncer.Msg -> Model -> ( Model, Effect Msg )
updateSearchDebouncer msg model =
    let
        ( debouncer, cmd ) =
            Debouncer.update searchDebouncerConfig msg model.searchDebouncer
    in
    model
        |> withSearchDebouncer debouncer
        |> Effect.withEffect (Effect.Embedded cmd)


callSearchDebouncer : String -> Model -> ( Model, Effect Msg )
callSearchDebouncer value model =
    let
        ( debouncer, cmd ) =
            Debouncer.call searchDebouncerConfig value model.searchDebouncer
    in
    model
        |> withSearchDebouncer debouncer
        |> Effect.withEffect (Effect.Embedded cmd)



-- MODEL SELECTORS


toEffectConfig : Model -> Effect.Config
toEffectConfig model =
    Effect.Config model.route model.navKey model.flags model.numistaApiKey


getCoin : Model -> Data.Coin.Id -> Maybe Coin
getCoin model id =
    case List.filter (Data.Coin.toId >> (==) id) model.coins of
        coin :: _ ->
            Just coin

        _ ->
            Nothing


hasCoins : Model -> Bool
hasCoins model =
    not (List.isEmpty model.coins)


hasModalDialog : Model -> Bool
hasModalDialog =
    .modalDialog >> Maybe.Extra.isJust



-- VIEW HELPERS


viewInitialized : Model -> Browser.Document Msg
viewInitialized model =
    case model.route of
        Route.Gallery options ->
            viewCoins model options

        Route.NotFound ->
            view404 ()


view404 : () -> Browser.Document Msg
view404 _ =
    viewErrorPage
        { title = "4😲4"
        , subtitle = Just "We are very sorry!"
        , message =
            "The file you requested is not available or was moved"
                ++ " to another location."
        , action =
            { link = "/app/"
            , title = "See your coins"
            }
        }


viewInitializationError : () -> Browser.Document msg
viewInitializationError _ =
    viewErrorPage
        { title = "Initialization error"
        , subtitle = Just "We are very sorry!"
        , message =
            "The app cannot be initialized. "
                ++ "Please contact support to get help."
        , action =
            { title = "Contact support"
            , link =
                "mailto:support@numistar.com"
                    ++ "?subject=Initialization error report"
            }
        }


viewErrorPage : UI.ErrorPageProps -> Browser.Document msg
viewErrorPage props =
    viewDocument props.title [ UI.errorPage props ]


viewBaseHeader : Html Msg -> Html Msg
viewBaseHeader slot =
    UI.header
        [ UI.burgerButton
            [ Html.Attributes.class "-ml-2.5"
            , Html.Attributes.title "Show navigation"
            , Html.Events.onClick BurgerButtonClicked
            ]
        , slot
        , UI.profileButton
            [ Html.Attributes.class "-mr-0.5"
            , Html.Attributes.title "Show profile"
            , Html.Events.onClick ProfileButtonClicked
            ]
        ]


viewDocument : String -> List (Html msg) -> Browser.Document msg
viewDocument title =
    Browser.Document (title ++ " • NUMISTAR")


viewCoins : Model -> Route.GalleryOptions -> Browser.Document Msg
viewCoins model options =
    let
        hasCoinsAtAll =
            hasCoins model

        -- filters applied
        filteredCoins =
            model.coins
                |> Data.Coin.filterBy options.filters

        -- additionally filtered by search terms and sorted
        visibleCoins =
            filteredCoins
                |> Data.Coin.filterBySearchTerms options.searchTerms
                |> List.sortWith (Data.Coin.compareBy options.sorting)

        hasVisibleCoins =
            not (List.isEmpty visibleCoins)

        isSortedByAveragePrice =
            options.sorting.attribute == Data.Sorting.AveragePrice

        toCoinCells coin =
            ( Data.Coin.toId coin
            , viewCoin
                { coin = coin
                , maxDiameter = Data.Coin.toMaxDiameter visibleCoins
                , galleryOptions = options
                , currency = model.currency
                , isPriceShown = isSortedByAveragePrice
                }
            )

        addCoinCell =
            ( "add-coin"
            , UI.addCoinButton
                [ Html.Attributes.title "Add coin"
                , Html.Events.onClick AddCoinButtonClicked
                ]
            )

        headerSlot =
            viewSearch
                { searchInput = model.searchInput
                , total = List.length model.coins
                , totalFiltered = List.length filteredCoins
                , count = List.length visibleCoins
                , isFiltered = not (List.isEmpty options.filters)
                }

        content =
            [ Html.Extra.viewIfLazy model.showStats <|
                \_ -> viewStats model.currency visibleCoins
            , UI.mainContainer [] <|
                if model.isPending then
                    [ Html.p [ Html.Attributes.class "text-center mt-6" ]
                        [ Html.text "Loading coins ..." ]
                    ]

                else if hasCoinsAtAll && not hasVisibleCoins then
                    [ Html.p
                        [ Html.Attributes.class "text-center mt-6 text-6xl"
                        , Html.Attributes.class "font-bold opacity-20"
                        ]
                        [ Html.text "¯\\_(ツ)_/¯" ]
                    , Html.p [ Html.Attributes.class "text-center mt-10" ]
                        [ Html.text "No coins found matching your criteria!" ]
                    ]

                else
                    [ UI.contentGrid { gridSize = options.gridSize }
                        [ Html.Attributes.class "-mx-2" ]
                        (if hasCoinsAtAll then
                            List.map toCoinCells visibleCoins

                         else
                            [ addCoinCell ]
                        )
                    ]
            , viewCoinsFooter options
            ]

        dialogView =
            case model.modalDialog of
                Just dialogModel ->
                    Dialog.view dialogModel
                        |> Dialog.View.mapView DialogMsg

                Nothing ->
                    Dialog.View.View "?" [] []
    in
    viewDocument "Your coins" <|
        [ UI.wrapper (viewBaseHeader headerSlot :: content)
        , viewNavigation
            { isVisible = model.isNavigationVisible
            , copyrightYear = model.flags.copyrightYear
            , version = model.flags.version
            }
        , UI.modalDialog
            { title = dialogView.title
            , onClose =
                model.modalDialog
                    |> Maybe.map ModalClosed
                    |> Maybe.withDefault NoOp
            , isOpen = Maybe.Extra.isJust model.modalDialog
            }
            dialogView.attributes
            dialogView.content
        ]


viewNavLink :
    { title : String, url : String }
    -> List (Html.Attribute msg)
    -> Html msg
viewNavLink { title, url } attributes =
    Html.a
        (List.append
            [ Html.Attributes.class "px-4 py-2 cursor-pointer transition-colors"
            , Html.Attributes.class "hover:bg-mint-light dark:hover:bg-mint-darker"
            , Html.Attributes.href url
            ]
            attributes
        )
        [ Html.text title ]


viewNavigation :
    { isVisible : Bool
    , copyrightYear : Int
    , version : String
    }
    -> Html Msg
viewNavigation { isVisible, copyrightYear, version } =
    UI.flyoutLeft { isOpen = isVisible, onClose = NavigationClosed }
        [ Html.Attributes.class "flex flex-col justify-between max-w-[75%]" ]
        [ Html.div []
            [ Html.p [ Html.Attributes.class "p-4 text-mint-dark w-44" ]
                [ Icons.numistarLogo [] ]
            , Html.nav [ Html.Attributes.class "flex flex-col" ]
                [ viewNavLink
                    { title = "FAQ"
                    , url = "/faq/"
                    }
                    []
                , viewNavLink
                    { title = "Legal notice"
                    , url = "/legal-notice/"
                    }
                    []
                , viewNavLink
                    { title = "Privacy policy"
                    , url = "/privacy/"
                    }
                    []
                ]
            ]
        , Html.p
            [ Html.Attributes.class "text-opacity-70 dark:text-opacity-60"
            , Html.Attributes.class "text-zinc-700 dark:text-zinc-300"
            , Html.Attributes.class "text-xs p-4"
            ]
            [ Html.text <| "Copyright © " ++ String.fromInt copyrightYear
            , Html.br [] []
            , Html.text "Belza Digital GmbH"
            , Html.br [] []
            , Html.text <| "Version " ++ version
            ]
        ]


viewStats : Currency -> List Coin -> Html Msg
viewStats currency coins =
    let
        sumTotal toNumber =
            coins
                |> List.map toNumber
                |> List.sum

        totalPurchasePrice =
            sumTotal (Data.Coin.toTotalCents >> Maybe.withDefault 0)

        totalWeight =
            sumTotal (Data.Coin.toTotalWeight >> Maybe.withDefault 0)

        numberOfAllCoins =
            sumTotal Data.Coin.toQuantity

        numberOfDifferentCoins =
            List.length coins

        numberOfIssuers =
            Data.Coin.toNumberOfIssuers coins

        maxDiameter =
            Data.Coin.toMaxDiameter coins

        minDiameter =
            Data.Coin.toMinDiameter coins

        maxWeight =
            Data.Coin.toMaxWeight coins

        minWeight =
            Data.Coin.toMinWeight coins

        maxMintingYear =
            Data.Coin.toMaxMintingYear coins

        minMintingYear =
            Data.Coin.toMinMintingYear coins

        formatMaybe maybeValue formatter =
            maybeValue
                |> Maybe.map formatter
                |> Maybe.withDefault "-"

        data =
            [ { label = "Purchase price"
              , value = Util.formatPrice currency totalPurchasePrice
              }
            , { label = "Material value"
              , value = "n/a"
              }
            , { label = "Total Weight"
              , value = Util.weightToString totalWeight
              }
            , { label = "Number of coins"
              , value = Util.formatInt numberOfAllCoins
              }
            , { label = "Different coins"
              , value = Util.formatInt numberOfDifferentCoins
              }
            , { label = "Number of issuers"
              , value = Util.formatInt numberOfIssuers
              }
            , { label = "Smallest coin"
              , value = formatMaybe minDiameter Util.diameterToString
              }
            , { label = "Largest coin"
              , value = formatMaybe maxDiameter Util.diameterToString
              }
            , { label = "Lightest coin"
              , value = formatMaybe minWeight Util.weightToString
              }
            , { label = "Heaviest coin"
              , value = formatMaybe maxWeight Util.weightToString
              }
            , { label = "Oldest coin"
              , value = formatMaybe minMintingYear String.fromInt
              }
            , { label = "Newest coin"
              , value = formatMaybe maxMintingYear String.fromInt
              }
            ]
    in
    Html.section
        [ Html.Attributes.class "bg-mint-light dark:bg-mint-darker"
        , Html.Attributes.class "-mb-14 px-5 pb-7 pt-[5rem] relative"
        ]
        [ UI.closeButton
            [ Html.Events.onClick <|
                WrappedGlobalMsg (GlobalMsg.StatsVisibilityChanged False)
            , Html.Attributes.class "absolute top-[4.25rem] right-[1.125rem]"
            , Html.Attributes.title "Close statistics"
            ]
        , Html.h2 [ Html.Attributes.class "sr-only" ] [ Html.text "Statistics" ]
        , Html.div
            [ Html.Attributes.class "gap-4 grid"
            , Html.Attributes.class "grid-cols-2 sm:grid-cols-3 md:grid-cols-4"
            , Html.Attributes.class "lg:grid-cols-5 xl:grid-cols-6"
            ]
            (List.map
                (\{ label, value } ->
                    Html.div []
                        [ Html.h3
                            [ Html.Attributes.class "text-xs font-bold"
                            , Html.Attributes.class "text-mint-dark"
                            , Html.Attributes.class "dark:text-mint"
                            ]
                            [ Html.text label ]
                        , Html.p [] [ Html.text value ]
                        ]
                )
                data
            )
        ]


viewSearch :
    { searchInput : String
    , total : Int
    , totalFiltered : Int
    , count : Int
    , isFiltered : Bool
    }
    -> Html Msg
viewSearch { searchInput, total, totalFiltered, count, isFiltered } =
    let
        placeholderText =
            [ [ "Search in"
              , String.fromInt totalFiltered
              ]
            , if isFiltered then
                [ "of", String.fromInt total ]

              else
                []
            , [ if totalFiltered == 1 then
                    "coin"

                else
                    "coins"
              ]
            ]
                |> List.concat
                |> String.join " "

        searchStats =
            String.join " / "
                [ String.fromInt count, String.fromInt totalFiltered ]
    in
    UI.searchBar
        { placeholder = placeholderText
        , inputSuffix = searchStats
        , onInput = SearchInputChanged
        , value = searchInput
        }
        [ Html.Attributes.class "ml-2 mr-3" ]


viewCoinsFooter : Route.GalleryOptions -> Html Msg
viewCoinsFooter options =
    let
        isFiltered =
            not (List.isEmpty options.filters)

        filterCount =
            List.length options.filters
    in
    UI.footer
        [ UI.box
            [ Html.Attributes.class "px-5 py-2 flex justify-between"
            , Html.Attributes.class "items-center"
            ]
            [ UI.box
                [ Html.Attributes.class "-ml-2.5 flex justify-start"
                , Html.Attributes.class "items-center"
                ]
                [ UI.box [ Html.Attributes.class "relative" ]
                    [ UI.filterButton
                        [ Html.Attributes.title "Filter coins"
                        , Html.Events.onClick FilterButtonClicked
                        ]
                    , Html.Extra.viewIf isFiltered <|
                        UI.counterBadge filterCount
                            [ Html.Attributes.class "absolute -top-2 -right-1"
                            , Html.Attributes.class "scale-75 bg-orange-500"
                            , Html.Attributes.class "pointer-events-none"
                            ]
                    ]
                , UI.sortButton options.sorting.direction
                    [ Html.Attributes.title "Sort coins"
                    , Html.Events.onClick SortButtonClicked
                    ]
                ]
            , UI.gridSizeSelector
                { selected = options.gridSize
                , onSelect = GridSizeSelected
                }
                [ Html.Attributes.title "Select coin grid size" ]
            , UI.box
                [ Html.Attributes.class "-mr-0.5 flex justify-end"
                , Html.Attributes.class "items-center"
                ]
                [ UI.diameterRatioButton
                    [ Html.Attributes.title "Show coin size ratio"
                    , Html.Events.onClick DiameterRatioButtonClicked
                    ]
                , UI.coinObverseButton
                    [ Html.Attributes.title "Flip coins"
                    , Html.Events.onClick CoinObverseButtonClicked
                    ]
                ]
            ]
        ]


viewCoin :
    { coin : Coin
    , maxDiameter : Maybe Float
    , galleryOptions : Route.GalleryOptions
    , currency : Currency
    , isPriceShown : Bool
    }
    -> Html Msg
viewCoin { coin, maxDiameter, galleryOptions, currency, isPriceShown } =
    let
        coinDiameter =
            Data.Coin.toDiameter coin

        coinAttrs =
            case ( maxDiameter, coinDiameter ) of
                ( Just max, Just diameter ) ->
                    [ Html.Attributes.class "transition-transform duration-500"
                    , Html.Attributes.Extra.attributeIf
                        galleryOptions.displayCoinDiameterRatio
                        (Html.Attributes.style "transform"
                            (String.join ""
                                [ "scale("
                                , String.fromFloat (1 / max * diameter)
                                , ")"
                                ]
                            )
                        )
                    ]

                _ ->
                    []

        quantity =
            Data.Coin.toQuantity coin

        showOutline =
            galleryOptions.displayCoinDiameterRatio
                && (case ( maxDiameter, coinDiameter ) of
                        ( Just max, Just diameter ) ->
                            diameter < max

                        _ ->
                            False
                   )
    in
    UI.box
        [ Html.Attributes.class "relative rounded-full border border-dashed"
        , Html.Attributes.class "border-transparent transition-colors"
        , Html.Attributes.Extra.attributeIf showOutline <|
            Html.Attributes.class "border-zinc-300 dark:border-zinc-700"
        ]
        [ UI.coinButton
            { coin = coin, showObverse = galleryOptions.showCoinObverse }
            (Html.Events.onClick (CoinSelected <| Data.Coin.toId coin)
                :: coinAttrs
            )
        , Html.Extra.viewIf (quantity > 1) <|
            UI.counterBadge quantity
                [ Html.Attributes.class "absolute right-0 top-0" ]
        , Html.Extra.viewIfLazy isPriceShown
            (\() ->
                Html.Extra.viewMaybe
                    (\cents ->
                        UI.priceLabel
                            { cents = cents, currency = currency }
                            [ Html.Attributes.class "absolute rotate-12"
                            , Html.Attributes.class "bottom-0 origin-left"
                            , case galleryOptions.gridSize of
                                Data.GridSize.S ->
                                    Html.Attributes.class
                                        "scale-75 sm:scale-90"

                                Data.GridSize.M ->
                                    Html.Attributes.class
                                        "scale-[0.85] sm:scale-100"

                                Data.GridSize.L ->
                                    Html.Attributes.class
                                        "scale-95 sm:scale-100"
                            ]
                    )
                    (Data.Coin.toAverageCents coin)
            )
        ]
